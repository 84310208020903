import React, { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import { ShowError, ShowSuccess } from '../Modules/Popups';

import loading_image from '../../images/loading2.gif';


const AccountSettings_ChangePassword = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    function GetHwid() {
        var browser = navigator.userAgent;
        var gl = document.createElement("canvas").getContext("webgl");
        var ext = gl.getExtension("WEBGL_debug_renderer_info");
        var videocard;
        if(ext) videocard = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);

        return browser+videocard;
    }

    function ChangePassword() {
        let password_old = document.getElementById('change-password-old').value;
        let password_new1 = document.getElementById('change-password-new1').value;
        let password_new2 = document.getElementById('change-password-new2').value;

        document.getElementById("change-password-button").style.display = 'none';
        document.getElementById("change-password-button-loading").style.display = 'flex';

        axios.post('https://api.0xcheats.su/Account/Settings/ChangePassword', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid(), 'password_old': password_old, 'password_new1': password_new1, 'password_new2': password_new2 }))
        .then(function(response) {
            const data = JSON.stringify(response.data);
            const json = JSON.parse(data);

            if (json.failed == null) {
                ShowSuccess(json.success);
                Close();
            }
            else {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            document.getElementById("change-password-button").style.display = 'flex';
            document.getElementById("change-password-button-loading").style.display = 'none';
        });
    }

    const isHover = e => e.parentElement.querySelector(':hover') === e;
    function Close() {
        document.getElementById('modal-wrapper').classList.remove('show');
        document.getElementById('modal-wrapper').classList.remove('fadeout');
        document.getElementById('modal').classList.remove('show');
    }
    function CloseFull() {
        const hovered = isHover(document.getElementById('modal'));
        if (!hovered)
            Close();
    }
    function Show() {
        ReactDOM.render(<AccountSettings_ChangePassword token={props.token}/>, document.getElementById('modals'));

        setTimeout(() => {
            document.getElementById('change-password-show').style.display = "none";

            document.getElementById('modal-wrapper').onclick = CloseFull;
            document.getElementById('modal-wrapper').classList.add('show');
            document.getElementById('modal-wrapper').classList.add('fadeout');
            document.getElementById('modal').classList.add('show');
            document.getElementById('body').style.display = "block";

            document.getElementById('change-password-button').onclick = ChangePassword;
        }, 200);
    }

    return (
    <>
        <div id="modal-wrapper" className="modal-wrapper" onClick={CloseFull}>
            <div id="modal" className="modal cash-up">

                <div id="body" className="body" style={{display:'none'}}>

                    <p className="pre-title fadeout">{t('settings_changepassword_title')}</p>

                    <div className="go-up fadeout">
                        <input id="change-password-old" type="password" maxLength='32' placeholder={t('settings_changepassword_password_1')} style={{width:'100%', marginRight:'0px'}}/>
                    </div><br/>
                    <div className="go-up fadeout">
                        <input id="change-password-new1" type="password" maxLength='32' placeholder={t('settings_changepassword_password_2')} style={{width:'100%', marginRight:'0px'}}/>
                    </div><br/>
                    <div className="go-up fadeout">
                        <input id="change-password-new2" type="password" maxLength='32' placeholder={t('settings_changepassword_password_3')} style={{width:'100%', marginRight:'0px'}}/>
                    </div><br/>

                    <div className="go-up fadeout">
                        <p id="change-password-button" className="btn black clickable" style={{background:'#3DB37A', margin:'5px', width:'100%'}}>{t('settings_changepassword_save')}</p>
                        <p id="change-password-button-loading" className="btn black clickable" style={{background:'#3DB37A', margin:'5px', width:'100%', display:'none'}}><img src={loading_image} width='168'/></p>
                    </div>
                </div>

            </div>
        </div>

        <p id="change-password-show" className="btn black note-action fadeout" data-note-type="warning" onClick={() => Show()}>{t('settings_changepassword_button')}</p>
    </>
    );
};
export default AccountSettings_ChangePassword;