import React, { useEffect } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';

import axios from 'axios';
import qs from 'qs';

import image_logo from '../images/logo.png';

const Account = () => {
    let { token, identifier } = useParams();

    useEffect(()=> {

        axios({
            method: 'post',
            url: 'https://api.0xcheats.su/Account/Download',
            data: qs.stringify({ 'token': token, 'identifier': identifier }),
            responseType: 'blob'
        })
        .then(function(response)
        {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', identifier + '_launcher.exe');
            document.body.appendChild(link);
            link.click();
        })
        .catch(function(error)
        {
            
        })
        .finally(function()
        {
            
        });
    }, [])

    function Instructions() {
        if (identifier == "altv" || identifier == "altvlite")
        {
            return (
            <>
                <h3>
                RU:<br/>
                Открывайте Лаунчер только от имени Администратора.<br/>
                Запускать софт нужно ОБЯЗАТЕЛЬНО до игры.<br/>
                <br/>
                После появления надписи "Waiting game..." начинайте запускать игру.<br/>
                Меню в игре открывается на Insert.<br/>
                <br/>
                В настройках игры должен стоять DirectX11!<br/>
                <br/>
                <br/>
                EN:<br/>
                Open Launcher only as Administrator.<br/>
                Need run software BEFORE the game.<br/>
                <br/>
                After "Waiting game..." message - start the game.<br/>
                Ingame menu opening on Insert or End.<br/>
                <br/>
                The game settings must be DirectX11!<br/>
                </h3>
            </>
            );
        }
        else {
            return (
            <>
                <h3>
                RU:<br/>
                Открывайте Лаунчер только от имени Администратора.<br/>
                Запускать софт нужно ОБЯЗАТЕЛЬНО до игры.<br/>
                <br/>
                После появления надписи "Waiting game..." начинайте запускать игру.<br/>
                Меню в игре открывается на Insert либо End.<br/>
                <br/>
                ВАЖНО:<br/>
                Если вы играете в другие игры с АНТИЧИТОМ - после использования 0x необходимо перезапустить пк, иначе вы рискуете получить блокировку!<br/>
                <br/>
                В настройках игры должен стоять DirectX11!<br/>
                <br/>
                <br/>
                EN:<br/>
                Open Launcher only as Administrator.<br/>
                Need run software BEFORE the game.<br/>
                <br/>
                After "Waiting game..." message - start the game.<br/>
                Ingame menu opening on Insert or End.<br/>
                <br/>
                IMPORTANT:<br/>
                If you play other games with ANTI-CHEAT - after using 0x, you must restart the PC, otherwise you risk getting banned!<br/>
                <br/>
                The game settings must be DirectX11!<br/>
                </h3>
            </>
            );
        }
    }

    return (
    <>
        <div className="container">
            <div className="content">
                <div className="main-info">
                    <img src={image_logo} alt=""/>
                    <br/>
                    <h2 style={{color: "#324589"}}>Загрузка файла, ожидайте...</h2>
                    <br/><br/>
                    <h2>Инструкция / Instruction</h2>
                    <br/>
                        
                    <Instructions/>

                    <br/><br/>
                    <h3><a href="https://0xcheats.net/account/" style={{color: "#324589"}}>Назад в профиль / Back to profile</a></h3>
                </div>
            </div>
        </div>
    </>
    );
};
export default Account;