import React, { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import { ShowError, ShowSuccess } from '../Modules/Popups';

import loading_image from '../../images/loading2.gif';


const AccountSettings_ChangeEmail = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    function GetHwid() {
        var browser = navigator.userAgent;
        var gl = document.createElement("canvas").getContext("webgl");
        var ext = gl.getExtension("WEBGL_debug_renderer_info");
        var videocard;
        if(ext) videocard = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);

        return browser+videocard;
    }

    function GetCode() {
        let new_email = document.getElementById('submit-email-1').value;

        document.getElementById("change-email-button").style.display = 'none';
        document.getElementById("change-email-button-loading").style.display = 'flex';

        //Валидация
        let patternEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        if (new_email.length > 0 && new_email.search(patternEmail) == 0) {

            axios.post('https://api.0xcheats.su/Account/Settings/ChangeEmail', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid(), 'new_email': new_email }))
            .then(function(response) {
                const data = JSON.stringify(response.data);
                const json = JSON.parse(data);

                if (json.success == "true") {
                    document.getElementById('body').style.display = "none";
                    document.getElementById('body2').style.display = "block";
                    document.getElementById('show-email-2').innerHTML = new_email;
                }
                else if (json.failed != null) {
                    ShowError(json.failed);
                }
            })
            .catch(function(error) {
                ShowError(error);
            })
            .finally(function() {
                document.getElementById("change-email-button").style.display = 'flex';
                document.getElementById("change-email-button-loading").style.display = 'none';
            });
        }
        else {
            document.getElementById("change-email-button").style.display = 'flex';
            document.getElementById("change-email-button-loading").style.display = 'none';
        }
    }
    function SendCode() {
        let new_email = document.getElementById('submit-email-1').value;
        let code = document.getElementById('submit-code').value;

        document.getElementById("change-email-button-2").style.display = 'none';
        document.getElementById("change-email-button-2-loading").style.display = 'flex';

        //Валидация
        let patternEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        if (new_email.length > 0 && new_email.search(patternEmail) == 0) {

            axios.post('https://api.0xcheats.su/Account/Settings/ChangeEmail', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid(), 'new_email': new_email, 'code': code }))
            .then(function(response) {
                const data = JSON.stringify(response.data);
                const json = JSON.parse(data);

                if (json.success == "true") {
                    ShowSuccess("Почта успешно изменена!");
                    Close();
                }
                else if (json.failed != null) {
                    ShowError(json.failed);
                }
            })
            .catch(function(error) {
                ShowError(error);
            })
            .finally(function() {
                document.getElementById("change-email-button-2").style.display = 'flex';
                document.getElementById("change-email-button-2-loading").style.display = 'none';
            });
        }
        else {
            document.getElementById("change-email-button-2").style.display = 'flex';
            document.getElementById("change-email-button-2-loading").style.display = 'none';
        }
    }

    const isHover = e => e.parentElement.querySelector(':hover') === e;
    function Close() {
        document.getElementById('modal-wrapper').classList.remove('show');
        document.getElementById('modal-wrapper').classList.remove('fadeout');
        document.getElementById('modal').classList.remove('show');
    }
    function CloseFull() {
        const hovered = isHover(document.getElementById('modal'));
        if (!hovered)
            Close();
    }
    function Show() {
        ReactDOM.render(<AccountSettings_ChangeEmail token={props.token}/>, document.getElementById('modals'));

        setTimeout(() => {
            document.getElementById('change-email-show').style.display = "none";

            document.getElementById('modal-wrapper').onclick = CloseFull;
            document.getElementById('modal-wrapper').classList.add('show');
            document.getElementById('modal-wrapper').classList.add('fadeout');
            document.getElementById('modal').classList.add('show');
            document.getElementById('body').style.display = "block";
            document.getElementById('body2').style.display = "none";

            document.getElementById('change-email-button').onclick = GetCode;
            document.getElementById('change-email-button-2').onclick = SendCode;
        }, 200);
    }

    return (
    <>
        <div id="modal-wrapper" className="modal-wrapper" onClick={CloseFull}>
            <div id="modal" className="modal cash-up">

                <div id="body" className="body" style={{display:'none'}}>

                    <p className="pre-title fadeout">{t('settings_changeemail_title')}</p>

                    <div className="go-up fadeout">
                        <input id="submit-email-1" type="email" maxLength='42' placeholder="example@gmail.com" style={{width:'100%', marginRight:'0px'}}/>
                    </div><br/>

                    <div className="go-up fadeout">
                        <p id="change-email-button" className="btn black clickable" style={{background:'#6382F5', margin:'5px', width:'100%'}}>{t('settings_changeemail_continue')}</p>
                        <p id="change-email-button-loading" className="btn black clickable" style={{background:'#6382F5', margin:'5px', width:'100%', display:'none'}}><img src={loading_image} width='168'/></p>
                    </div>
                </div>

                <div id="body2" className="body" style={{display:'none'}}>

                    <p className="pre-title fadeout">{t('settings_changeemail_description_1')} <b>"<span id="show-email-2">example@gmail.com</span>"</b> {t('settings_changeemail_description_2')}
                    <br/>{t('settings_changeemail_confirm')}</p>

                    <div className="go-up fadeout">
                        <input id="submit-code" type="email" maxLength='42' placeholder="ABCDE" style={{width:'100%', marginRight:'0px'}}/>
                    </div><br/>

                    <div className="go-up fadeout">
                        <p id="change-email-button-2" className="btn black clickable" style={{background:'#3DB37A', margin:'5px', width:'100%'}}>{t('settings_changeemail_title_2')}</p>
                        <p id="change-email-button-2-loading" className="btn black clickable" style={{background:'#3DB37A', margin:'5px', width:'100%', display:'none'}}><img src={loading_image} width='168'/></p>
                    </div>
                </div>

            </div>
        </div>

        <p id="change-email-show" className="btn black note-action fadeout" data-note-type="warning" onClick={() => Show()}>{t('settings_changeemail_button')}</p>
    </>
    );
};
export default AccountSettings_ChangeEmail;