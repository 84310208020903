import React, { useEffect, useRef } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Turnstile } from '@marsidev/react-turnstile';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import Header from './Header';
import Footer from './Footer';

import Popups, {ShowError} from './Modules/Popups';

import loading_image from '../images/loading2.gif';
import empty_image from "../images/empty.png"
import user_image from "../images/user.jpg"


const Recovery = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    const [Redirect, SetRedirect] = React.useState(false);

    let { code } = useParams();
    const captcha_ref = useRef(null);

    //Сбрасываем авторизацию
    removeCookie("token", { path: "/" });
    removeCookie("is_coder", { path: "/" });
    removeCookie("is_reseller", { path: "/" });

    useEffect(()=> {
        axios.post('https://api.0xcheats.su/Account/Recovery', qs.stringify({ 'code': code }))
        .then(function(response) {
            const data = JSON.stringify(response.data);
            const json = JSON.parse(data);
            
            if (json.success == "true") {

            }
            else if (json.failed != null) {
                SetRedirect(true);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {

        });
    }, [])

    if (Redirect) {
        return <Navigate to={"/auth"} replace />;
    }

    function StartRecovery() {
        let password1 = document.getElementById("password1").value;
        let password2 = document.getElementById("password2").value;
        let captcha = captcha_ref.current?.getResponse();

        //Пароли не совпадают
        if (password1 != password2) {
            ShowError(t("recovery_error_1"));
            return;
        }

        //Пароль должен быть больше 6 символов
        if (password1.length < 6) {
            ShowError(t("recovery_error_2"));
            return;
        }

        document.getElementById("recovery-button1").style.display = 'none';
        document.getElementById("recovery-button2").style.display = 'flex';

        axios.post('https://api.0xcheats.su/Account/Recovery', qs.stringify({ 'password1': password1, 'password2': password2, 'code': code, 'captcha': captcha }))
        .then(function(response) {
            const data = JSON.stringify(response.data);
            const json = JSON.parse(data);
            
            if (json.success == "true") {
                setCookie("token", json.token, { path: "/" });
                SetRedirect(true);
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            document.getElementById("recovery-button1").style.display = 'flex';
            document.getElementById("recovery-button2").style.display = 'none';
        });
    }

    return (
    <>
        <Popups/>

        <Header/>

        <section id="settings" className="profile-page fadeout">
            <div className="container">
                <div className="content">

                    <div className="modal2-wrapper">
                        <div className="modal cash-up show">
                            <div className="header">
                                <img className="avatar" alt="" src={user_image} draggable="false"/>
                                <p className="name">{t('recovery_title')}</p>
                            </div>

                            <div id="body-loading" className="body">

                                <p className="pre-title fadeout" style={{marginTop: '10px', marginBottom: '15px'}}>{t('recovery_password_1')}</p>
                                <input id="password1" className="inputtext" type="password" maxLength="32" placeholder="*************"/>

                                <p className="pre-title fadeout" style={{marginTop: '10px', marginBottom: '15px'}}>{t('recovery_password_2')}</p>
                                <input id="password2" className="inputtext" type="password" maxLength="32" placeholder="*************"/>

                                <br/>
                                <center>
                                    <br/><br/><Turnstile ref={captcha_ref} siteKey='0x4AAAAAAAR1hh-tMNilglf7'/>
                                </center>

                                <br/><br/>
                                <div className="go-up fadeout">
                                    <p id="recovery-button1" className="btn clickable" style={{width: '100%'}} onClick={StartRecovery}><span>{t('recovery_save')}</span></p>
                                    <p id="recovery-button2" className="btn" style={{display: "none", width: '100%'}}><span><img src={loading_image} width='168'/></span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/><br/>

                </div>
            </div>
        </section>

        {/*<Footer/>*/}
    </>
    );
};
export default Recovery;