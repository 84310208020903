import React, { useEffect, useRef } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import axios from 'axios';
import qs from 'qs';

import Popups, {ShowError} from './Modules/Popups';

import empty_image from "../images/empty.png";
import image_loading from "../images/loading2.gif";
import image_logo from '../images/help/logo.jpg';


const AccountSupporterMessages = (props) => {
    const messagesContainerRef = useRef(null);

    const [cookies, setCookie, removeCookie] = useCookies(['name']);

    const [LastTicket, SetLastTicket] = React.useState(0);
    const [LastMessage, SetLastMessage] = React.useState("");
    const [MessagesParsed, SetMessagesParsed] = React.useState(false);
    const [MessagesArray, SetMessagesArray] = React.useState([]);

    function decrypt(encryptedData) {
        const encryptedBytes = atob(encryptedData);
        const keyBytes = unescape(encodeURIComponent("MyEncryptionKey123"));

        const decryptedBytes = new Uint8Array(encryptedBytes.length);

        for (let i = 0; i < encryptedBytes.length; i++) {
            const encryptedByte = encryptedBytes.charCodeAt(i);
            const keyByte = keyBytes.charCodeAt(i % keyBytes.length);

            // Пропускаем символы, не соответствующие ASCII
            if (encryptedByte > 255 || keyByte > 255) {
                decryptedBytes[i] = encryptedByte;
            } else {
                decryptedBytes[i] = encryptedByte ^ keyByte;
            }
        }

        const decoder = new TextDecoder('utf-8');
        const decryptedString = decoder.decode(decryptedBytes);

        return JSON.parse(decryptedString);
    }

    function MessageLocal(props) {
        if (props.is_local && props.type == 0)
        {
            return (
            <div id={"message" + props.id} key={props.id} className="msg">
                <div className="info">
                    <div className="text">{props.message.replaceAll('<br />', '\r\n')}</div>
                </div>
                {/*<div className="postfix">
                    <span className="name">Вы </span>
                    <span className="date">{props.date}</span>
                </div>*/}
            </div>
            );
        }
    }
    function MessageOther(props) {
        if (!props.is_local && props.type == 0)
        {
            return (
            <div id={"message" + props.id} key={props.id} className="msg admin">
                <div className="flex">
                    <a href={props.user_id === 0 ? "#" : "#" + props.user_username + "-" + props.user_id}>
                        <img src={props.user_avatar !== "" ? "https://api.0xcheats.su/Images/Avatars/" + props.user_avatar : "https://api.0xcheats.su/Images/Avatars/invalid.jpg"} alt="" className="avatar" draggable="false"/>
                        
                        <br/>
                        <center>
                        <a style={{ margin:'auto', marginLeft:'-10px', fontSize:'10px', color: props.user_supporter === 1 ? "red" : "grey" }}>{props.user_username}</a>
                        </center>
                    </a>
                    <div className="info">
                        <div className="text">{props.message.replaceAll('<br />', '\r\n')}</div>
                    </div>
                    <p style={{marginLeft:'5px', marginTop:'auto', marginBottom:'7px', fontSize:'12px', color:'#ccc'}}>{props.date}</p>
                </div>
            </div>
            );
        }
    }
    function MessageInfo(props) {
        if (props.type == 1)
        {
            return (
            <div id={"message" + props.id} key={props.id} className="msg admin" style={{marginLeft:'auto'}}>
                <div className="flex">
                    <div className="info" style={{background:'#000', borderRadius:'20px 20px 20px 20px', color:'white'}}>
                        <img src={props.user_avatar !== "" ? "https://api.0xcheats.su/Images/Avatars/" + props.user_avatar : "https://api.0xcheats.su/Images/Avatars/invalid.jpg"} alt="" className="avatar" style={{borderRadius:'50%', border: '1px solid #EA384D'}} draggable="false"/>
                        <div className="text" style={{display:'flex', alignItems:'center'}}>{props.message.replace('<br />', '\r\n')}</div>
                    </div>
                    <p style={{marginLeft: "5px", marginTop: "auto", marginBottom: "7px", fontSize: "12px", 'color': '#ccc'}}>{props.date}</p>
                </div>
            </div>
            );
        }
    }
    function MessageImageLocal(props) {
        if (props.is_local && props.type == 2)
        {
            return (
            <div id={"message" + props.id} key={props.id} className="msg">
                <a href={props.message} target="_blank">
                    <img src={props.message} alt="" style={{'max-width': '250px', 'border-radius': '10px'}}/>
                </a>
                {/*<div className="postfix">
                    <span className="name">Вы </span>
                    <span className="date">{props.date}</span>
                </div>*/}
            </div>
            );
        }
    }
    function MessageImageOther(props) {
        if (!props.is_local && props.type == 2)
        {
            return (
            <div id={"message" + props.id} key={props.id} className="msg admin">
                <div className="flex">
                    <a href={props.user_id === 0 ? "#" : "#" + props.user_username + "-" + props.user_id}>
                        <img src={props.user_avatar !== null ? "https://api.0xcheats.su/Images/Avatars/" + props.user_avatar : "https://api.0xcheats.su/Images/Avatars/invalid.jpg"} alt="" className="avatar"/>
                        {/*<br/><a style={{'margin': "auto", 'font-size': "14px", 'color': "red"}}>{props.user_username}</a>*/}
                    </a>
                    <a href={props.message} target="_blank">
                        <img src={props.message} alt="" style={{'max-width': '250px', 'border-radius': '10px'}}/>
                    </a>
                    <p style={{marginLeft: "5px", marginTop: "auto", marginBottom: "7px", fontSize: "12px", 'color': '#ccc'}}>{props.date}</p>
                </div>
                {/*<div className="postfix">
                    <span className="name">Мл.Модератор </span>
                    <span className="date">{props.date}</span>
                </div>*/}
            </div>
            );
        }
    }

    if ((LastTicket != props.ticket && props.ticket != 0) || LastMessage != props.message) {

        SetLastTicket(props.ticket);
        SetLastMessage(props.message);
        SetMessagesParsed(false);

        axios.post('https://api.0xcheats.su/Account/Supporter/Ticket/Messages', qs.stringify({ 'token': cookies.token, 'ticket': props.ticket }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);
            
            if (json.success == "true") {
                SetMessagesParsed(true);
                SetMessagesArray(json.messages);

                document.getElementById("ticket_title").textContent = json.title;
                document.getElementById("ticket_description").textContent = json.description;

                //Если тикет закрыт
                if (json.status == 0)
                {
                    document.getElementById("ticket_send_message").style.display = "none";
                    document.getElementById("ticket_send_message_closed").style.display = "flex";
                    
                    document.getElementById("ticket_close_text").style.display = "none";
                    document.getElementById("ticket_close_button").style.display = "none";
                }
                else {
                    document.getElementById("ticket_send_message").style.display = "flex";
                    document.getElementById("ticket_send_message_closed").style.display = "none";

                    document.getElementById("ticket_close_text").style.display = "block";
                    document.getElementById("ticket_close_button").style.display = "flex";
                }
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            setTimeout(() => {

                //Отображаем кнопку отправки сообщения
                document.getElementById('create_message_button').style.display = "flex";
                document.getElementById('create_message_button2').style.display = "none";

                //Сбрасываем поле ввода
                document.getElementById('ticket_new_message').value = "";

                //Листаем список сообщений вниз
                const elementToScroll = document.getElementById('ticket_messages');
                elementToScroll.scrollTop = elementToScroll.scrollHeight;

            }, 100);
        });
    }

    if (MessagesParsed || LastMessage.length > 0) {
        return MessagesArray.map((message) =>
            <React.Fragment key={message.id}>
                <MessageLocal       id={message.id} message={message.message} type={message.type} is_local={message.is_local} user_id={message.user_id} user_username={message.user_username} user_avatar={message.user_avatar} user_supporter={message.user_supporter} date={message.date}/>
                <MessageOther       id={message.id} message={message.message} type={message.type} is_local={message.is_local} user_id={message.user_id} user_username={message.user_username} user_avatar={message.user_avatar} user_supporter={message.user_supporter} date={message.date}/>
                <MessageInfo        id={message.id} message={message.message} type={message.type} is_local={message.is_local} user_id={message.user_id} user_username={message.user_username} user_avatar={message.user_avatar} user_supporter={message.user_supporter} date={message.date}/>
                <MessageImageLocal  id={message.id} message={message.message} type={message.type} is_local={message.is_local} user_id={message.user_id} user_username={message.user_username} user_avatar={message.user_avatar} user_supporter={message.user_supporter} date={message.date}/>
                <MessageImageOther  id={message.id} message={message.message} type={message.type} is_local={message.is_local} user_id={message.user_id} user_username={message.user_username} user_avatar={message.user_avatar} user_supporter={message.user_supporter} date={message.date}/>
            </React.Fragment>
        );
    }
    else {
        return (
        <>
            <div className="msg fadeout">
                <div className="info">
                    <div className="text loading">Крашит софт при запуске :(</div>
                </div>
            </div>

            <div className="msg admin fadeout">
                <div className="flex">
                    <a className="avatar loading"/>
                    <div className="info">
                        <div className="text loading">Уже исправили 🔥</div>
                    </div>
                </div>
            </div>

            <div className="msg fadeout">
                <div className="info">
                    <div className="text loading">Спасибо вам за помощь 💙 💙</div>
                </div>
            </div>

            <div className="msg admin fadeout">
                <div className="flex">
                    <a className="avatar loading"/>
                    <div className="info">
                        <div className="text loading">Спасибо за обращение 💙 💙</div>
                    </div>
                </div>
            </div>
        </>
        );
    }
};
export default AccountSupporterMessages;