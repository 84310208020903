import React, { useEffect, useRef } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Turnstile } from '@marsidev/react-turnstile';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import Header from './Header';
import Footer from './Footer';

import Popups, { ShowSuccess, ShowError } from './Modules/Popups';

import loading_image from '../images/loading2.gif';
import empty_image from "../images/empty.png"
import user_image from "../images/user.jpg"


const AuthForgot = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    const [Redirect, SetRedirect] = React.useState(false);

    let { code } = useParams();
    const captcha_ref = useRef(null);

    //Сбрасываем авторизацию
    removeCookie("token", { path: "/" });
    removeCookie("is_coder", { path: "/" });
    removeCookie("is_reseller", { path: "/" });

    if (Redirect) {
        return <Navigate to={"/auth"} replace />;
    }

    function StartRecovery() {
        let email = document.getElementById("email").value;
        let captcha = captcha_ref.current?.getResponse();

        document.getElementById("recovery-button1").style.display = 'none';
        document.getElementById("recovery-button2").style.display = 'flex';

        //Валидация
        let patternEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        if (email.length > 0 && email.search(patternEmail) == 0) {
            axios.post('https://api.0xcheats.su/Account/Forgot', qs.stringify({ 'email': email, 'captcha': captcha }))
            .then(function(response) {
                const data = JSON.stringify(response.data);
                const json = JSON.parse(data);
                
                if (json.success == "true") {
                    ShowSuccess(t("forgot_success_1"));
                    document.getElementById("recovery-button1").style.display = 'none';
                    document.getElementById("recovery-button2").style.display = 'none';
                }
                else if (json.failed != null) {
                    ShowError(json.failed);
                }
            })
            .catch(function(error) {
                ShowError(error);
            })
            .finally(function() {
                document.getElementById("recovery-button1").style.display = 'flex';
                document.getElementById("recovery-button2").style.display = 'none';
            });
        }
        else {
            ShowError(t("forgot_error_1"));

            document.getElementById("recovery-button1").style.display = 'flex';
            document.getElementById("recovery-button2").style.display = 'none';
        }
    }

    return (
    <>
        <Popups/>

        <Header/>

        <section id="settings" className="profile-page fadeout">
            <div className="container">
                <div className="content">

                    <div className="modal2-wrapper">
                        <div className="modal cash-up show">
                            <div className="header">
                                <img className="avatar" alt="" src={user_image} draggable="false"/>
                                <p className="name">{t('forgot_title')}</p>
                            </div>

                            <div id="body-loading" className="body">

                                <p className="pre-title fadeout" style={{marginTop: '10px', marginBottom: '15px'}}>{t('forgot_email')}</p>
                                <input id="email" className="inputtext" type="email" maxLength="32" placeholder="account@gmail.com"/>

                                <br/>
                                <center>
                                    <br/><br/><Turnstile ref={captcha_ref} siteKey='0x4AAAAAAAR1hh-tMNilglf7'/>
                                </center>

                                <br/><br/>
                                <div className="go-up fadeout">
                                    <p id="recovery-button1" className="btn clickable" style={{width:'100%'}} onClick={StartRecovery}><span>{t('forgot_send')}</span></p>
                                    <p id="recovery-button2" className="btn" style={{display:'none', width:'100%'}}><span><img src={loading_image} width='168'/></span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/><br/>

                </div>
            </div>
        </section>

        {/*<Footer/>*/}
    </>
    );
};
export default AuthForgot;