import React, { useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { curveCardinal } from 'd3-shape';
import { useCookies } from 'react-cookie';

import axios from 'axios';
import qs from 'qs';

import {ShowError} from '../Modules/Popups';


const AccountCoder_Month = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const [Data, SetData] = React.useState(null);

    useEffect(()=> {
        axios.post('https://api.0xcheats.su/Account/Coder/Stats/Month', qs.stringify({ 'token': cookies.token }))
        .then(function(response) {
            const data = JSON.stringify(response.data);
            const json = JSON.parse(data);

            if (json.success == "true") {
                const newData = [
                { name: '1', Текущий: 0, Прошлый: 0 },
                { name: '2', Текущий: 0, Прошлый: 0 },
                { name: '3', Текущий: 0, Прошлый: 0 },
                { name: '4', Текущий: 0, Прошлый: 0 },
                {
                  name: '5',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '6',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '7',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '8',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '9',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '10',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '11',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '12',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '13',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '14',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '15',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '16',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '17',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '18',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '19',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '20',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '21',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '22',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '23',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '24',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '25',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '26',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '27',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '28',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '29',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '30',
                  Текущий: 0,
                  Прошлый: 0,
                },
                {
                  name: '31',
                  Текущий: 0,
                  Прошлый: 0,
                }
              ];
                
                //Текущий месяц
                const current = JSON.parse(JSON.stringify(json.current));
                current.forEach((op) => {
                    const value = JSON.parse(JSON.stringify(op));
                    newData[Number(value.day)-1]['Текущий'] = Number(op.totalAmount);
                });

                //Прошлый месяц
                const past = JSON.parse(JSON.stringify(json.past));
                past.forEach((op) => {
                    const value = JSON.parse(JSON.stringify(op));
                    newData[Number(value.day)-1]['Прошлый'] = Number(op.totalAmount);
                });

                SetData(newData);
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            
        });
    }, [])


    const cardinal = curveCardinal.tension(0.2);

    return (
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={400}
            height={400}
            data={Data}
            margin={{top: 10, right: 30, left: 0, bottom: 45}}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Area type={cardinal} dataKey="Прошлый" stroke="#ffc658" fill="#ffc658" fillOpacity={0.5}/>
            <Area type="monotone" dataKey="Текущий" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.5}/>
          </AreaChart>

        </ResponsiveContainer>
    );
};
export default AccountCoder_Month;