import React, { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import {ShowError, ShowSuccess, ShowInfo} from './Modules/Popups';

import image_logo from '../images/logo.png';

const Header = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    useEffect(()=> {
        if (document.location.search.length > 0) {
            var new_token = document.location.search.replace("?key=", "");
            setCookie("token", new_token, { path: "/" });
            setCookie("gotoaccount", true, { path: "/" });
            window.close();
        }
    }, [])

    if (cookies.gotoaccount != null) {
        removeCookie("gotoaccount", { path: "/" });
        window.location.href = '/account';
    }

    function Account() {
        if (cookies.token != null) {
            //Переходим в профиль
            window.location.href = '/account';
        }
        else {
            window.location.href = '/auth';
            
            ////Открываем окно авторизации
            //var _left = window.screen.width/2 - 440/2;
            //var _top = window.screen.height/2 - 570/2;
            //var args = 'width=440, height=570, top=' + _top + ', left=' + _left;
            //
            //let created_window = window.open('https://0x.ltd/widget/J5h3ZvOs6sAsPmA9wk165FG2sjWNZrh8', 'auth', args);
            //created_window.focus();
            //
            //setInterval(function () { if (created_window.closed) window.location.reload(); }, 500);
        }
    }

    function MobileMenu() {
        let mobileMenu = document.getElementById("mobile-menu");
        let mobileMenuOverlay = document.getElementById("mobile-menu-overlay");
        if (mobileMenu != null && mobileMenuOverlay != null) {
            //Если меню открыто
            if (mobileMenu.classList.contains("active")) {
                mobileMenu.classList.remove("active");
                mobileMenuOverlay.classList.remove("show");
            }
            //Если меню закрыто
            else {
                mobileMenu.classList.add("active");
                mobileMenuOverlay.classList.add("show");
            }
        }
    }

    return (
    <>
        <div id="mobile-menu-overlay" className="mobile-menu-overlay">
            <Link to="/" className="link">{t('header_main')}</Link>
            <Link to="/games" className="link">{t('header_products')}</Link>
            <Link to="/feed" className="link">{t('header_news')}</Link>
            <Link to="/faq" className="link">{t('header_faq')}</Link>
            <Link to="/help" className="link">{t('header_help')}</Link>
            <a className="btn icon-left clickable" onClick={Account}><i className="icon user"></i><span>{t('header_cabinet')}</span></a>
        </div>

        <div className="main_header_bg"></div>
        <header className="main_header">
            <div className="container">
                <div className="navbar">
                    <Link to="/" className="logo">
                        <img src={image_logo} alt="" draggable="false"/>
                        <span className="info">
                            <span className="name">0xcheats</span>
                            <span>private</span>
                        </span>
                    </Link>
                    <div className="navbar-menu">
                        <Link id="_index" to="/">{t('header_main')}</Link>
                        <Link id="_games" to="/games">{t('header_products')}</Link>
                        <Link id="_feed" to="/feed">{t('header_news')}</Link>
                        <Link id="_faq" to="/faq">{t('header_faq')}</Link>
                        <Link id="_help" to="/help">{t('header_help')}</Link>
                    </div>
                    <a className="btn icon-left clickable" onClick={Account}>
                        <i className="icon user"></i>
                        <span>{t('header_cabinet')}</span>
                    </a>
                    <label id="mobile-menu" className="mobile-menu"><input type="checkbox" onClick={MobileMenu}/><span></span><span></span><span></span></label>
                </div>
            </div>
        </header>
    </>
    );
};
export default Header;