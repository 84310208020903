import React, { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import { ShowError, ShowSuccess } from '../Modules/Popups';
import loading_image from '../../images/loading2.gif';


const AccountReseller_Buy = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    function decrypt(encryptedData) {
        const encryptedBytes = atob(encryptedData);
        const keyBytes = unescape(encodeURIComponent("MyEncryptionKey123"));

        const decryptedBytes = new Uint8Array(encryptedBytes.length);

        for (let i = 0; i < encryptedBytes.length; i++) {
            const encryptedByte = encryptedBytes.charCodeAt(i);
            const keyByte = keyBytes.charCodeAt(i % keyBytes.length);

            // Пропускаем символы, не соответствующие ASCII
            if (encryptedByte > 255 || keyByte > 255) {
                decryptedBytes[i] = encryptedByte;
            } else {
                decryptedBytes[i] = encryptedByte ^ keyByte;
            }
        }

        const decoder = new TextDecoder('utf-8');
        const decryptedString = decoder.decode(decryptedBytes);

        return JSON.parse(decryptedString);
    }

    function GetHwid() {
        var browser = navigator.userAgent;
        var gl = document.createElement("canvas").getContext("webgl");
        var ext = gl.getExtension("WEBGL_debug_renderer_info");
        var videocard;
        if(ext) videocard = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);

        return browser+videocard;
    }

    let region = "";
    function ParseTariffs() {
        axios.post('https://api.0xcheats.su/Account/Reseller/Tariffs', qs.stringify({ 'token': props.token, 'identifier': props.identifier }))
        .then(function(response)
        {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);
            
            if (json.success == "true")
            {
                document.getElementById('body-loading').style.display = "none";
                document.getElementById('body').style.display = "block";

                //Очищаем и обновляем тарифов
                ClearTariffsList();

                region = json.region;

                //Отображаем список тарифов
                json.tariffs.forEach(function(tariff) {
                    const versionObject = document.createElement('div');
                    versionObject.id = tariff.hours;
                    versionObject.className = 'radio-box';
                    versionObject.addEventListener('click', () => SelectTariff(tariff.hours, tariff.price, tariff.price_usd));

                    let hours = tariff.hours/24 + ' ' + t('reseller_buy_days');
                    if (tariff.hours >= 1000000)
                        hours = 'Навсегда';

                    const versionInfo = document.createElement('p');
                    if (region == "RU") {
                        if (tariff.price == 0)
                            versionInfo.textContent = hours + ' ' + t('reseller_buy_free');
                        else
                            versionInfo.textContent = hours + ' (' + tariff.price + ' Руб ' + t('reseller_buy_for_key') + ')';
                    }
                    else {
                        if (tariff.price_usd == 0)
                            versionInfo.textContent = hours + ' ' + t('reseller_buy_free');
                        else
                            versionInfo.textContent = hours + ' (' + tariff.price_usd + '$ ' + t('reseller_buy_for_key') + ')';
                    }                     
                    versionObject.appendChild(versionInfo);

                    const versionCheck = document.createElement('div');
                    versionCheck.className = 'radio';
                    versionObject.appendChild(versionCheck);

                    const versionCheckSpan = document.createElement('span');
                    versionCheck.appendChild(versionCheckSpan);

                    document.getElementById('tariffs').appendChild(versionObject);
                });
            }
            else if (json.failed !== null)
            {
                ShowError(json.failed);
            }
        })
        .catch(function(error)
        {
            ShowError(error);
        })
        .finally(function()
        {
            
        });
    }

    let selectedHours = 0;
    let selectedPrice = 0;
    let selectedPriceUSD = 0;
    function SelectTariff(tariff, price, price_usd) {

        //Отключаем все галочки
        document.getElementById("current").classList.remove('active');
        const active = document.getElementById("tariffs").querySelector('.active');
        if (active)
            active.classList.remove('active');

        //Включаем выбранную галочку
        document.getElementById(tariff).classList.add('active');

        selectedHours = tariff;
        selectedPrice = price;
        selectedPriceUSD = price_usd;
        ChangeTotalPrice();

        //Отображаем поле количества и кнопку покупки
        document.getElementById("buy-count").style.display = "flex";
        document.getElementById("buy-button").style.display = "flex";
        document.getElementById("buy-button-2").style.display = "flex";
    }
    function ClearTariffsList() {

        const versions = document.getElementById('tariffs');
        while (versions.firstChild)
            versions.removeChild(versions.firstChild);
    }

    function OpenBuyLink() {
        let software = props.identifier;
        let hours = selectedHours;
        let count = document.getElementById('buy-count').value;

        axios.post('https://api.0xcheats.su/Account/Reseller/CreatePayment', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid(), 'currency':"rub", 'software': software, 'hours': hours, 'count': count }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);

            if (json.success == "true") {
                if (json.link == "free") {
                    ShowSuccess("Ключи созданы! Обновите страницу!");
                }
                else {
                    window.open(json.link, "_blank");
                }
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            // Любые завершающие действия
        });
    }
    function OpenBuyLink2() {
        let software = props.identifier;
        let hours = selectedHours;
        let count = document.getElementById('buy-count').value;

        axios.post('https://api.0xcheats.su/Account/Reseller/CreatePayment', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid(), 'currency':"usd", 'software': software, 'hours': hours, 'count': count }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);

            if (json.success == "true") {
                if (json.link == "free") {
                    ShowSuccess("Ключи созданы! Обновите страницу!");
                }
                else {
                    window.open(json.link, "_blank");
                }
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            // Любые завершающие действия
        });
    }
    function ChangeTotalPrice() {
        let count = document.getElementById('buy-count').value;
        let price = selectedPrice * count;
        let priceUSD = selectedPriceUSD * count;

        if (count != 0 && price == 0) {
            document.getElementById('buy-button').innerText = "Получить (Бесплатно)";
            document.getElementById('buy-button-2').innerText = "Получить (Бесплатно)";
        }
        else {
            document.getElementById('buy-button').innerText = "Оплатить (" + price.toFixed(2) + " Руб)";
            document.getElementById('buy-button-2').innerText = "Payment (" + priceUSD.toFixed(2) + "$)";
        }
    }

    const isHover = e => e.parentElement.querySelector(':hover') === e;
    function Close() {
        document.getElementById('modal-wrapper').classList.remove('show');
        document.getElementById('modal-wrapper').classList.remove('fadeout');
        document.getElementById('modal').classList.remove('show');
    }
    function CloseFull() {
        const hovered = isHover(document.getElementById('modal'));
        if (!hovered)
            Close();
    }
    function Show() {
        ReactDOM.render(<AccountReseller_Buy identifier={props.identifier} name={props.name}/>, document.getElementById('modals'));

        setTimeout(() => {
            document.getElementById('modal-wrapper').onclick = CloseFull;
            document.getElementById('modal-wrapper').classList.add('show');
            document.getElementById('modal-wrapper').classList.add('fadeout');
            document.getElementById('modal').classList.add('show');
            document.getElementById('body-loading').style.display = "block";
            document.getElementById('body').style.display = "none";

            document.getElementById("buy-count").style.display = "none";
            document.getElementById('buy-button').onclick = OpenBuyLink;
            document.getElementById("buy-button").style.display = "none";
            document.getElementById('buy-button-2').onclick = OpenBuyLink2;
            document.getElementById("buy-button-2").style.display = "none";

            document.getElementById('buy-count').addEventListener('input', ChangeTotalPrice);
            
            ParseTariffs();
        }, 200);
    }

    return (
    <>
        <div id="modal-wrapper" className="modal-wrapper" onClick={CloseFull}>
            <div id="modal" className="modal cash-up">
                <div className="header">
                    <img className="avatar" alt="" src={"https://api.0xcheats.su/Images/Games/" + props.identifier +"/logo.jpg"}/>
                    <p className="name">{props.name}</p>
                    <span>{t('reseller_buy_title')}</span>
                </div>

                <div id="body-loading" className="body">
                    <p className="pre-title fadeout">{t('reseller_buy_description')}</p>
                    <div className="radio-boxes fadeout">
                        <div className="radio-box loading">
                            <div className="radio"><span></span></div>
                        </div>
                        <div className="radio-box loading">
                            <div className="radio"><span></span></div>
                        </div>
                        <div className="radio-box loading">
                            <div className="radio"><span></span></div>
                        </div>
                    </div>
                </div>

                <div id="body" className="body" style={{display:'none'}}>

                    <p className="pre-title fadeout">{t('reseller_buy_description')}</p>
                    <div id="tariffs" className="radio-boxes fadeout">
                        <div id="current" className="radio-box active" onClick={() => SelectTariff("current")}>
                            <p>7 дней</p>
                            <div className="radio"><span></span></div>
                        </div>
                    </div>

                    <div className="go-up fadeout">
                        <input id="buy-count" style={{marginRight:'0px'}} type="text" maxLength='5' placeholder={t('reseller_buy_keys_count')}/>
                    </div>
                    <br/>
                    <div className="go-up fadeout">
                        <p id="buy-button" className="btn black clickable" style={{background:'#3DB37A', margin:'5px', width:'100%'}}>Оплатить (0$)</p>
                        <p id="buy-button-2" className="btn black clickable" style={{background:'#3DB37A', margin:'5px', width:'100%'}}>Payment (0 $)</p>
                    </div>
                </div>

            </div>
        </div>

        <div className="status-view active" onClick={() => Show()}><i className="icon reseller"></i></div>
    </>
    );
};
export default AccountReseller_Buy;