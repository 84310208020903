import React, { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import AccountReseller_Buy from './Modals/AccountReseller_Buy';

import Popups, { ShowSuccess, ShowError } from './Modules/Popups';
import AccountPartner_Week from './Modules/AccountPartner_Week';
import AccountPartner_Month from './Modules/AccountPartner_Month';
import AccountPartner_Stats from './Modules/AccountPartner_Stats';

import Account_Header from './Modules/Account_Header';
import Account_Navigation from './Modules/Account_Navigation';

import empty_image from "../images/empty.png"


const AccountReseller = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    const [InvalidToken, SetInvalidToken] = React.useState(false);

    const [Id, SetId] = React.useState(0);
    const [Username, SetUsername] = React.useState("Username");
    const [Avatar, SetAvatar] = React.useState("Avatar");
    const [Balance, SetBalance] = React.useState(0);

    const [PromocodesParsed, SetPromocodesParsed] = React.useState(false);
    const [PromocodesArray, SetPromocodesArray] = React.useState([]);

    const [LinksParsed, SetLinksParsed] = React.useState(false);
    const [LinksArray, SetLinksArray] = React.useState([]);

    function decrypt(encryptedData) {
        const encryptedBytes = atob(encryptedData);
        const keyBytes = unescape(encodeURIComponent("MyEncryptionKey123"));

        const decryptedBytes = new Uint8Array(encryptedBytes.length);

        for (let i = 0; i < encryptedBytes.length; i++) {
            const encryptedByte = encryptedBytes.charCodeAt(i);
            const keyByte = keyBytes.charCodeAt(i % keyBytes.length);

            // Пропускаем символы, не соответствующие ASCII
            if (encryptedByte > 255 || keyByte > 255) {
                decryptedBytes[i] = encryptedByte;
            } else {
                decryptedBytes[i] = encryptedByte ^ keyByte;
            }
        }

        const decoder = new TextDecoder('utf-8');
        const decryptedString = decoder.decode(decryptedBytes);

        return JSON.parse(decryptedString);
    }
    function GetHwid() {
        var browser = navigator.userAgent;
        var gl = document.createElement("canvas").getContext("webgl");
        var ext = gl.getExtension("WEBGL_debug_renderer_info");
        var videocard;
        if(ext) videocard = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);

        return browser+videocard;
    }

    useEffect(()=> {
        if (cookies.balance != null) SetBalance(cookies.balance);
        
        axios.post('https://api.0xcheats.su/Account/Partner/Info', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid() }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);

            if (json.success == "true") {

                //User
                SetId(json.id);
                SetUsername(json.username);

                if (json.avatar.length == 0)
                    SetAvatar("https://api.0xcheats.su/Images/Avatars/invalid.jpg");
                else
                    SetAvatar("https://api.0xcheats.su/Images/Avatars/" + json.avatar);

                SetBalance(json.balance);
                setCookie("balance", json.balance, { path: "/" });

                //Промокоды
                SetPromocodesParsed(true);
                SetPromocodesArray(json.promocodes);

                //Ссылки
                SetLinksParsed(true);
                SetLinksArray(json.links);
            }
            else if (json.failed != null) {
                SetInvalidToken(true);
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {

        });
    }, [])

    if (InvalidToken) {
        removeCookie("token", { path: "/" });
        removeCookie("is_partner", { path: "/" });
        return <Navigate to="/auth" replace />;
    }


    function DateFormat(date) {
        const newDate = new Date(date);
        const day = newDate.getDate().toString().padStart(2, '0');
        const month = (newDate.getMonth() + 1).toString().padStart(2, '0'); // Месяцы начинаются с 0
        const year = newDate.getFullYear();
        const hours = newDate.getHours().toString().padStart(2, '0');
        const minutes = newDate.getMinutes().toString().padStart(2, '0');
        const formattedDate = `${day}.${month}.${year} в ${hours}:${minutes}`;
        return formattedDate;
    }
    function ShowPromocodeStatistics(promocode) {
        alert(promocode);

        // let keysBlock = document.getElementById(bulkId);
        // let iconBlock = document.getElementById("icon" + bulkId);
        //
        // if (keysBlock != null) {
        //     if (keysBlock.style.display == "block") {
        //         iconBlock.classList.remove("eye2");
        //         iconBlock.classList.add("eye3");
        //         keysBlock.style.display = "none";
        //     }
        //     else {
        //         iconBlock.classList.remove("eye3");
        //         iconBlock.classList.add("eye2");
        //         keysBlock.style.display = "block";
        //     }
        // }
    }
    function ShowLinkStatistics(promocode) {
        alert(promocode);
    }

    function Promocodes() {
        if (PromocodesParsed) {
            return PromocodesArray.map((promo) =>
            <>
            <div key={promo.code} className="block-wrapper">
                <div className="block">
                    <div className="header">
                        <a style={{marginRight:'4px'}}><b>{promo.code}</b></a><a style={{marginLeft:'4px', marginRight:'4px'}}>|</a><a style={{marginRight:'4px'}}>Активировано</a> <b>{promo.uses} шт</b><a style={{marginLeft:'4px', marginRight:'4px'}}>|</a><a style={{marginRight:'4px'}}>Покупок</a> <b>{promo.buys} шт</b>
                        <p className="icon-wrapper clickable" style={{marginLeft:'auto', marginRight:'0px', background:'rgba(228, 228, 228, 0)'}} onClick={() => ShowPromocodeStatistics(promo.code)}><i className="icon month" style={{background:'rgb(68, 68, 68)'}}></i></p>
                    </div>
                </div>
            </div>
            <br/>
            </>
            );
        }
        else {
            
        }
    }
    function Links() {
        if (LinksParsed) {
            return LinksArray.map((link) =>
            <>
            <div key={link.link} className="block-wrapper">
                <div className="block">
                    <div className="header">
                        <a style={{marginRight:'4px'}}><b>{link.link}</b></a><a style={{marginLeft:'4px', marginRight:'4px'}}>|</a><a style={{marginRight:'4px'}}>Переходов</a> <b>{link.views} шт</b><a style={{marginLeft:'4px', marginRight:'4px'}}>|</a><a style={{marginRight:'4px'}}>Регистраций</a> <b>{link.registrations} шт</b>
                        <p className="icon-wrapper clickable" style={{marginLeft:'auto', marginRight:'0px', background:'rgba(228, 228, 228, 0)'}} onClick={() => ShowLinkStatistics(link.link)}><i className="icon month" style={{background:'rgb(68, 68, 68)'}}></i></p>
                    </div>
                </div>
            </div>
            <br/>
            </>
            );
        }
        else {
            
        }
    }

    if (Id == 0) {
        return (
        <>
            <Popups/>
            <div className="mobile-menu-overlay">
                <div className="input activate-key"></div>
                <div className="payment-block"></div>
            </div>

            <section id="settings" className="profile-page">
                <Account_Navigation active={"partner"}/>
                <div className="container">
                    <div className="content">
                        <Account_Header username={Username} id={Id} avatar={Avatar} balance={Balance}/>
                        <div className="hl-reson"></div>
                        <div className="pre-title no-m fadeout"><i className="icon products"></i>{t('partner_statistic')}</div>

                        <div className="blocks fadeout">
                            <div className="block-wrapper">
                                <div className="block" style={{height: '25vh', width: "100%"}}>
                                </div>
                            </div>
                            <div className="block-wrapper">
                                <div className="block" style={{height: '25vh', width: "100%"}}>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id="history" className="profile-page">

                <div className="container fadeout">
                    <div className="content">

                        <div className="hl-reson"></div>
                        <div className="pre-title no-m"><i className="icon products"></i>{t('partner_promocodes')}</div>

                        <div className="history-buy no-m">

                            <div className="product-wrapper">
                                <div className="product loading"></div>
                            </div>

                            <div className="product-wrapper">
                                <div className="product loading"></div>
                            </div>

                            <div className="product-wrapper">
                                <div className="product loading"></div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
        );
    }
    else {
        return (
        <>
            <Popups/>

            <section id="settings" className="profile-page">
                <Account_Navigation active={"partner"}/>
                <div className="container">
                    <div className="content">
                        <Account_Header username={Username} id={Id} avatar={Avatar} balance={Balance}/>
                        <div className="hl-reson"></div>
                        <div className="pre-title no-m fadeout"><i className="icon products"></i>{t('partner_statistic')}</div>

                        <div className="blocks fadeout">
                            <div className="block-wrapper">
                                <div className="block" style={{height: '25vh', width: "100%"}}>
                                    <div className="header">
                                        <p className="icon-wrapper"><i className="icon week"></i></p>{t('partner_week_money')}
                                    </div>
                                    <br/><AccountPartner_Week/><br/>
                                </div>
                            </div>
                            <div className="block-wrapper">
                                <div className="block" style={{height: '25vh', width: "100%"}}>
                                    <div className="header">
                                        <p className="icon-wrapper"><i className="icon month"></i></p>{t('partner_month_money')}
                                    </div>
                                    <br/><AccountPartner_Month/><br/>
                                </div>
                            </div>
                            <div className="block-wrapper">
                                <div className="block" style={{height: '25vh', width: "100%"}}>
                                    <div className="header">
                                        <p className="icon-wrapper"><i className="icon hwid"></i></p>Соотношение продуктов
                                    </div>
                                    <br/><AccountPartner_Stats/><br/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id="settings" className="profile-page">
                <div className="container">
                    <div className="content">
                        <div className="hl-reson"></div>
                        <div className="pre-title no-m fadeout"><i className="icon promocode"></i>{t('partner_promocodes')}</div>
                        <div className="history-buy no-m">
                            <div className="blocks fadeout">
                                <Promocodes/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="settings" className="profile-page">
                <div className="container">
                    <div className="content">
                        <div className="hl-reson"></div>
                        <div className="pre-title no-m fadeout"><i className="icon link"></i>{t('partner_links')}</div>
                        <div className="history-buy no-m">
                            <div className="blocks fadeout">
                                <Links/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
        );
    }
};
export default AccountReseller;