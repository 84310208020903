import React, { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import axios from 'axios';
import qs from 'qs';

import AccountCoder_Upload from './Modals/AccountCoder_Upload';
import AccountCoder_Manage from './Modals/AccountCoder_Manage';

import Popups, {ShowError} from './Modules/Popups';
import AccountCoder_Week from './Modules/AccountCoder_Week';
import AccountCoder_Month from './Modules/AccountCoder_Month';
import AccountCoder_Stats from './Modules/AccountCoder_Stats';

import Account_Header from './Modules/Account_Header';
import Account_Navigation from './Modules/Account_Navigation';

import AccountCoder_Terminal from './Modals/AccountCoder_Terminal';

import empty_image from "../images/empty.png"


const AccountCoder = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);

    const [InvalidToken, SetInvalidToken] = React.useState(false);

    const [Id, SetId] = React.useState(0);
    const [Username, SetUsername] = React.useState("Username");
    const [Avatar, SetAvatar] = React.useState("Avatar");
    const [Balance, SetBalance] = React.useState(0);

    const [HacksParsed, SetHacksParsed] = React.useState(false);
    const [HacksArray, SetHacksArray] = React.useState([]);

    const [ServersParsed, SetServersParsed] = React.useState(false);
    const [ServersArray, SetServersArray] = React.useState([]);

    function decrypt(encryptedData) {
        const encryptedBytes = atob(encryptedData);
        const keyBytes = unescape(encodeURIComponent("MyEncryptionKey123"));

        const decryptedBytes = new Uint8Array(encryptedBytes.length);

        for (let i = 0; i < encryptedBytes.length; i++) {
            const encryptedByte = encryptedBytes.charCodeAt(i);
            const keyByte = keyBytes.charCodeAt(i % keyBytes.length);

            // Пропускаем символы, не соответствующие ASCII
            if (encryptedByte > 255 || keyByte > 255) {
                decryptedBytes[i] = encryptedByte;
            } else {
                decryptedBytes[i] = encryptedByte ^ keyByte;
            }
        }

        const decoder = new TextDecoder('utf-8');
        const decryptedString = decoder.decode(decryptedBytes);

        return JSON.parse(decryptedString);
    }

    function GetHwid() {
        var browser = navigator.userAgent;
        var gl = document.createElement("canvas").getContext("webgl");
        var ext = gl.getExtension("WEBGL_debug_renderer_info");
        var videocard;
        if(ext) videocard = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);

        return browser+videocard;
    }

    useEffect(()=> {
        if (cookies.balance != null) SetBalance(cookies.balance);
        
        axios.post('https://api.0xcheats.su/Account/Coder/Info', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid() }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);

            if (json.success == "true")
            {
                //User
                SetId(json.id);
                SetUsername(json.username);

                if (json.avatar.length == 0)
                    SetAvatar("https://api.0xcheats.su/Images/Avatars/invalid.jpg");
                else
                    SetAvatar("https://api.0xcheats.su/Images/Avatars/" + json.avatar);

                SetBalance(json.balance);
                setCookie("balance", json.balance, { path: "/" });

                //Список серверов
                SetServersParsed(true);
                SetServersArray(json.servers);

                //Список софтов
                SetHacksParsed(true);
                SetHacksArray(json.hacks);
            }
            else if (json.failed != null) {
                SetInvalidToken(true);
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {

        });

        //Обновление серверов в реальном времени
        // setInterval(() => {
        //     axios.post('https://api.0xcheats.su/Account/Coder/Info', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid() }))
        //     .then(function(response) {
        //         const data = JSON.stringify(decrypt(response.data));
        //         const json = JSON.parse(data);
        //
        //         if (json.success == "true")
        //         {
        //             //Список серверов
        //             SetServersParsed(true);
        //             SetServersArray(json.servers);
        //         }
        //     })
        //     .catch(function(error) {
        //
        //     })
        //     .finally(function() {
        //
        //     });
        // }, 5000);
    }, [])

    if (InvalidToken) {
        removeCookie("token", { path: "/" });
        removeCookie("is_coder", { path: "/" });
        return <Navigate to="/auth" replace />;
    }


    function Hacks() {
        if (HacksParsed) {
            return HacksArray.map((hack) =>
            <div className="product-wrapper" key={hack.identifier}>
                <div className="product">
                    <img src={"https://api.0xcheats.su/Images/Games/" + hack.identifier +"/logo.jpg"} alt="" draggable="false"/>
                    <div className="info">
                        <div className="text">
                            <span className="status-text active">Ставка: {hack.percents}%</span>
                            <p className="name">{hack.name}</p>
                        </div>
                        { hack.can_manage == 1 ? <AccountCoder_Manage token={cookies.token} identifier={hack.identifier} name={hack.name}/> : "" }
                        { hack.can_upload == 1 ? <AccountCoder_Upload token={cookies.token} identifier={hack.identifier} name={hack.name}/> : "" }
                    </div>
                </div>
            </div>
            );
        }
        else {
            return (
            <>
                <div className="product-wrapper">
                    <div className="product loading"></div>
                </div>

                <div className="product-wrapper">
                    <div className="product loading"></div>
                </div>

                <div className="product-wrapper">
                    <div className="product loading"></div>
                </div>
            </>
            );
        }
    }

    function Servers() {
        if (ServersParsed) {
            return ServersArray.map((server) =>
            <div key={server.ip} className="block-wrapper">
                <div className="block">
                    <div className="header">

                        { Math.floor(new Date(server.timestamp).getTime() / 1000) > Math.floor(new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Moscow"})).getTime() / 1000) - 30 ? <><p className="icon-wrapper"><i className="icon month"></i></p>{server.name} | {server.ip} | ЦП: {server.cp}%, ОЗУ: {server.ram}%</> : "" }
                        { Math.floor(new Date(server.timestamp).getTime() / 1000) < Math.floor(new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Moscow"})).getTime() / 1000) - 30 ? <><p className="icon-wrapper"><i className="icon month"></i></p>{server.name} | {server.ip}</> : "" }

                        { Math.floor(new Date(server.timestamp).getTime() / 1000) > Math.floor(new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Moscow"})).getTime() / 1000) - 30 ? <p style={{marginLeft:'auto', 'color':'#3DB27A'}}>Онлайн</p> : "" }
                        { Math.floor(new Date(server.timestamp).getTime() / 1000) < Math.floor(new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Moscow"})).getTime() / 1000) - 30 ? <p style={{marginLeft:'auto', 'color':'#FF9B29'}}>Оффлайн</p> : "" }
                        
                        { Math.floor(new Date(server.timestamp).getTime() / 1000) > Math.floor(new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Moscow"})).getTime() / 1000) - 30 ? <AccountCoder_Terminal name={server.name} ip={server.ip}/> : "" }


                    </div>
                </div>
            </div>
            );
        }
        else {
            return (
            <>
                <div className="product-wrapper">
                    <div className="product loading"></div>
                </div>
            </>
            );
        }
    }


    if (Id == 0) {
        return (
        <>
            <Popups/>
            <div className="mobile-menu-overlay">
                <div className="input activate-key"></div>
                <div className="payment-block"></div>
            </div>

            <section id="settings" className="profile-page">
                <Account_Navigation active={"coder"}/>
                <div className="container">
                    <div className="content">
                        <Account_Header username={Username} id={Id} avatar={Avatar} balance={Balance}/>
                        <div className="hl-reson"></div>
                        <div className="pre-title no-m fadeout"><i className="icon products"></i>Статистика</div>

                        <div className="blocks fadeout">
                            <div className="block-wrapper">
                                <div className="block" style={{height: '25vh', width: "100%"}}>
                                </div>
                            </div>
                            <div className="block-wrapper">
                                <div className="block" style={{height: '25vh', width: "100%"}}>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id="history" className="profile-page">

                <div className="container fadeout">
                    <div className="content">

                        <div className="hl-reson"></div>
                        <div className="pre-title no-m"><i className="icon products"></i>Управление продуктами</div>

                        <div className="history-buy no-m">

                            <div className="product-wrapper">
                                <div className="product loading"></div>
                            </div>

                            <div className="product-wrapper">
                                <div className="product loading"></div>
                            </div>

                            <div className="product-wrapper">
                                <div className="product loading"></div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
        );
    }
    else {
        return (
        <>
            <Popups/>
            <div className="mobile-menu-overlay">
                <div className="input activate-key">
                    <p className="btn black">Активировать</p>
                    <input type="text" placeholder="Введите код активации"/>
                </div>
                <div className="payment-block">
                    <div className="balance">
                        <a href="" className="btn black go-up-balance">Пополнить</a>
                        <span>50 000 <b>руб</b></span>
                    </div>
                    <div className="pay"><i className="icon payment"></i></div>
                </div>
            </div>

            <section id="settings" className="profile-page">
                <AccountCoder_Upload/>
                <Account_Navigation active={"coder"}/>
                <div className="container">
                    <div className="content">
                        <Account_Header username={Username} id={Id} avatar={Avatar} balance={Balance}/>
                        <div className="hl-reson"></div>
                        <div className="pre-title no-m fadeout"><i className="icon products"></i>Статистика</div>

                        <div className="blocks fadeout">
                            <div className="block-wrapper">
                                <div className="block" style={{height: '25vh', width: "100%"}}>
                                    <div className="header">
                                        <p className="icon-wrapper"><i className="icon week"></i></p>Доход за неделю
                                    </div>
                                    <br/><AccountCoder_Week/><br/>
                                </div>
                            </div>
                            <div className="block-wrapper">
                                <div className="block" style={{height: '25vh', width: "100%"}}>
                                    <div className="header">
                                        <p className="icon-wrapper"><i className="icon month"></i></p>Доход за месяц
                                    </div>
                                    <br/><AccountCoder_Month/><br/>
                                </div>
                            </div>
                            <div className="block-wrapper">
                                <div className="block" style={{height: '25vh', width: "100%"}}>
                                    <div className="header">
                                        <p className="icon-wrapper"><i className="icon hwid"></i></p>Соотношение продуктов
                                    </div>
                                    <br/><AccountCoder_Stats/><br/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id="settings" className="profile-page">
                <div className="container">
                    <div className="content">
                        <div className="hl-reson"></div>
                        <div className="pre-title no-m fadeout"><i className="icon products"></i>Сервера</div>
                        <div className="blocks fadeout">
                            <Servers/>
                        </div>
                    </div>
                </div>
            </section>

            <section id="history" className="profile-page">

                <div className="container fadeout">
                    <div className="content">
                        <div className="hl-reson"></div>
                        <div className="pre-title no-m"><i className="icon products"></i>Управление продуктами</div>
                        <div className="history-buy no-m">
                            <Hacks/>
                        </div>
                    </div>
                </div>
            </section>
        </>
        );
    }
};
export default AccountCoder;